// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dom_env: 'fiat',
  dynamikLinkToapp: 'https://goelivetest.page.link/Tbeh',
  apiUrl: '/api',

  sapApikey: '3_L8BBe9Jfa1iIoi9oU6tmcSJhUOQZpD-87XHA5hGPsBLYXqewf-1bv42-IpPbG2eU',

  idpBroker: {
    link: 'https://api02-emea-qa.fcagroup.com/emea/extra/IdPBroker/b2c/v1/users',
    user: 'b140bb4c-b8eb-4ada-a034-787db8ac9912',
    key: 'Y7pP0aL7rC0lO2mH8hG2aP8pA4dM1sT6iU6fJ5cF7dE6kM7wD5'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
